import request from "@/utils/request";

// 获取table
export function getTable(params) {
  return request({
    url: "/message",
    method: "Get",
    params: params,
  });
}

// 添加
export function addMessage(requestBody) {
  return request({
    url: "/message",
    method: "Post",
    data: requestBody,
  });
}

// 编辑
export function editMessage(params, requestBody) {
  return request({
    url: "/message",
    method: "Patch",
    params: params,
    data: requestBody,
  });
}

// 删除
export function deleteMessage(params) {
  return request({
    url: "/message",
    method: "Delete",
    params: params,
  });
}

// 暂停
export function switchStatus(params) {
  return request({
    url: "/message/pause-resume",
    method: "Patch",
    params: params,
  });
}
