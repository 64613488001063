<template>
  <div>
    <el-dialog
      v-model="visible"
      @open="onOpen"
      @close="onClose"
      width="60%"
      title="添加短信"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="mini"
        label-width="px"
        label-position="left"
      >
        <el-form-item label-width="100px" label="企业名称" prop="companyName">
          <el-select
            v-model="formData.companyName"
            placeholder="请输入企业名称"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
            @change="handleCompanyNameSelectChange"
          >
            <el-option
              v-for="item in options.companyNameOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label-width="100px"
          label="监控点名称"
          prop="monitoryPointName"
        >
          <el-select
            v-model="formData.monitoryPointName"
            placeholder="请输入监控点名称"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="item in options.monitoryPointNameOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="100px" label="运维人员" prop="contact">
          <el-input
            v-model="formData.contact"
            placeholder="请输入运维人员"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="100px" label="手机号" prop="phone">
          <el-input
            v-model="formData.phone"
            placeholder="请输入运维人员手机号"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="150px" label="数据类型" prop="dataType">
          <div class="block" style="margin-right: 1%">
            <el-select
              @change="handleDataTypeChange"
              size="mini"
              v-model="select.dataType"
            >
              <el-option
                v-for="item in options.dataTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item
          label-width="150px"
          label="数据连续超标次数"
          prop="continueExceedCount"
        >
          <el-input
            v-model="formData.continueExceedCount"
            placeholder="请输入数据连续超标次数"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item
          label-width="150px"
          label="设备断线时长"
          prop="breakDuration"
        >
          <el-input
            v-model="formData.breakDuration"
            placeholder="请输入设备断线时长"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item
          label-width="150px"
          label="报警时短信发送间隔"
          prop="messageSendInterval"
        >
          <el-input
            v-model="formData.messageSendInterval"
            placeholder="请输入报警时短信发送间隔"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
      </el-form>
      <div>
        <slot name="footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="handleConfirm">确定</el-button>
        </slot>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getCompanyNameOptions } from "@/api/company";
import { getMonitoryPointOptionsByCompanyName } from "@/api/monitory-point";
import { addMessage } from "@/api/message";

export default {
  name: "MessageAddDialog",
  components: {},
  props: { dialogVisible: Boolean },
  data() {
    return {
      visible: false,
      formData: {
        companyName: "",
        monitoryPointName: "",
        continueExceedCount: "",
        breakDuration: "",
        messageSendInterval: "",
        contact: "",
        phone: "",
        dataType: "real",
      },
      options: {
        companyNameOptions: [],
        monitoryPointNameOptions: [],
        dataTypeOptions: [
          {
            value: "real",
            label: "实时数据",
          },
          {
            value: "minute",
            label: "分钟数据",
          },
          {
            value: "hour",
            label: "小时数据",
          },
        ],
      },
      select: {
        dataType: "real",
      },
      rules: {
        monitoryPointName: [
          {
            required: true,
            message: "请输入监控点名称",
            trigger: "blur",
          },
        ],
        companyName: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            message: "请输入运维人员",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入运维人员手机号",
            trigger: "blur",
          },
        ],
        dataType: [
          {
            required: true,
            message: "请选择数据类型",
            trigger: "blur",
          },
        ],
        continueExceedCount: [
          {
            required: true,
            message: "请输入数据连续超标次数",
            trigger: "blur",
          },
        ],
        breakDuration: [
          {
            required: true,
            message: "请输入设备断线时长",
            trigger: "blur",
          },
        ],
        messageSendInterval: [
          {
            required: true,
            message: "请输入报警时短信发送间隔",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value;
      },
    },
  },
  created() {
    this.getOptions();
  },
  mounted() {
    this.visible = this.dialogVisible;
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$refs["elForm"].resetFields();
      this.$emit("updateVisible");
    },
    close() {
      this.$emit("updateVisible");
    },
    handleConfirm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        const requestBody = this.formData;
        addMessage(requestBody).then(() => {
          // 重新请求table
          this.$parent.getList();
        });

        this.close();
      });
    },
    handleDataTypeChange(val) {
      console.log(val);
      this.select.dataType = val;
      this.formData.dataType = val;
    },
    handleCompanyNameSelectChange(companyName) {
      const params = {
        companyName: companyName,
      };
      getMonitoryPointOptionsByCompanyName(params).then((result) => {
        this.options.monitoryPointNameOptions = result.data;
      });
    },
    // 获取企业名称
    getOptions() {
      getCompanyNameOptions().then((result) => {
        this.options.companyNameOptions = result.data;
      });
    },
  },
};
</script>
<style></style>
