<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-chat-dot-round"></i> 信息管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>短信管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-col :span="5">
          <el-input
            clearable
            size="mini"
            v-model="monitoryPointNameOrMnInput"
            placeholder="监控点名 / MN号"
          >
            <template #prepend
              ><i class="el-icon-search"></i>输入搜索:</template
            >
          </el-input>
        </el-col>
        <div style="float: left">
          <el-button
            @click="handleClear"
            plain
            icon="el-icon-delete"
            size="mini"
          >
            清空</el-button
          >
          <el-button
            @click="handleSearch"
            plain
            type="primary"
            icon="el-icon-search"
            size="mini"
          >
            查询
          </el-button>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row>
        <el-button
          @click="handleAddMessage"
          type="primary"
          icon="el-icon-edit"
          size="mini"
        >
          添加短信
        </el-button>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" :index="1">
        </el-table-column>
        <el-table-column prop="areaName" label="区域名称"> </el-table-column>
        <el-table-column prop="companyName" label="企业名称"> </el-table-column>
        <el-table-column prop="monitoryPointName" label="监控点名称">
        </el-table-column>
        <el-table-column prop="mn" label="MN号"> </el-table-column>
        <el-table-column prop="contact" label="联系人"> </el-table-column>
        <el-table-column prop="phone" label="手机"> </el-table-column>
        <el-table-column prop="dataType" label="数据类型">
          <template #default="scope">
            <span v-if="scope.row.dataType === 'real'">实时数据</span>
            <span v-if="scope.row.dataType === 'hour'">小时数据</span>
          </template>
        </el-table-column>
        <el-table-column prop="enable" label="状态">
          <template #default="scope">
            <el-switch
              v-model="scope.row.enable"
              @click="handleSwitch(scope.$index, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100px">
          <template #default="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="text"
              @click="handleDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <message-add-dialog
      :dialog-visible="dialogVisibleMessageAdd"
      @updateVisible="closeAddMessage"
    >
    </message-add-dialog>
    <message-edit-dialog
      :dialog-visible="dialogVisibleMessageEdit"
      @updateVisible="closeEditMessage"
      :row="editMessage"
    >
    </message-edit-dialog>
  </div>
</template>

<script>
import MessageAddDialog from "@/views/info/message/dialog/MessageAddDialog";
import MessageEditDialog from "@/views/info/message/dialog/MessageEditDialog";
import { deleteMessage, getTable, switchStatus } from "@/api/message";

export default {
  name: "Message",
  components: { MessageEditDialog, MessageAddDialog },
  data() {
    return {
      monitoryPointNameOrMnInput: "",
      dialogVisibleMessageAdd: false,
      dialogVisibleMessageEdit: false,
      editMessage: {},
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleClear() {
      this.monitoryPointNameOrMnInput = "";
    },
    handleSearch() {
      console.log(this.monitoryPointNameOrMnInput);
      this.getList();
    },
    handleAddMessage() {
      this.dialogVisibleMessageAdd = true;
    },
    closeAddMessage() {
      this.dialogVisibleMessageAdd = false;
    },
    handleEdit(index, row) {
      this.editMessage = JSON.parse(JSON.stringify(row));
      this.dialogVisibleMessageEdit = true;
    },
    closeEditMessage() {
      this.dialogVisibleMessageEdit = false;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.editMessage = row;
      this.$confirm("此操作将永久删除该短信发送, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 请求参数
          const params = {
            monitoryPointName: row.monitoryPointName,
            phone: row.phone,
            dataType: row.dataType,
          };
          // 发送请求
          deleteMessage(params).then(() => {
            // 重新请求table
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSwitch(index, row) {
      console.log(index, row);
      // 请求参数
      const params = {
        monitoryPointName: row.monitoryPointName,
        phone: row.phone,
        dataType: row.dataType,
      };
      switchStatus(params);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pagination.currentPage = val;
      this.getList();
    },
    getList() {
      const params = {
        monitoryPointNameOrMn: this.monitoryPointNameOrMnInput,
        pageSize: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
      };
      getTable(params).then((result) => {
        const data = result.data;
        this.pagination.total = data.total;
        this.pagination.pageSize = data.pageSize;
        this.pagination.currentPage = data.currentPage;
        data.list.forEach((item) => {
          item.enable = item.enable === 1;
        });
        this.tableData = data.list;
      });
    },
  },
};
</script>

<style scoped></style>
