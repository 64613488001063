import request from "@/utils/request";

// 获取table
export function getTable(params) {
  return request({
    url: "/company",
    method: "Get",
    params: params,
  });
}

// 增加
export function addCompany(requestBody) {
  return request({
    url: "/company",
    method: "Post",
    data: requestBody,
  });
}

// 编辑
export function editCompany(requestBody) {
  return request({
    url: "/company",
    method: "Patch",
    data: requestBody,
  });
}

// 删除
export function deleteCompany(params) {
  return request({
    url: "/company",
    method: "Delete",
    params: params,
  });
}

// 获取企业名称 option
export function getCompanyNameOptions() {
  return request({
    url: "/company/option",
    method: "Get",
  });
}

// 暂停
export function switchStatus(params) {
  return request({
    url: "/company/alert-status",
    method: "Put",
    params: params,
  });
}